<template>
  <div id="firma" class="firma">
    <div v-if="loading" class="loading_firma">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>
    <v-container v-else grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12 md12>
          <v-card class="mb-4">
            <v-toolbar color="blue" dark flat dense cad>
              <v-toolbar-title class="subheading white--text">{{ $t('talonario.conf_firma') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider />
            <v-card-text class="">
              <div>{{ $t('talonario.dibuje_firma') }}</div>
              <center>
                <div id="firma_Container" style="height: 180px; cursor: default;">
                  <v-signature
                    v-if="modificar_signature"
                    ref="signature"
                    :sig-option="option"
                    :w="'300px'"
                    :h="'180px'"
                    class="firma_nueva"
                  />
                  <v-btn
                    :disabled="disabledButton"
                    style="font-size: 10px;"
                    color="light-blue lighten-5 blue-grey--text"
                    @click="clear"
                  >
                    <v-icon style="font-size: 14px;">mdi-cached</v-icon>&nbsp;{{ $t('talonario.rehacer_firma') }}
                  </v-btn>
                </div>
                <br clear="all" />
                <br clear="all" />
              </center>
              <div class="botonera">
                <v-btn
                  :disabled="disabledButton"
                  class="blue"
                  :color="'info'"
                  dark
                  style="font-size: 12px;"
                  @click="saveFirma"
                  >{{ $t('common.save') }}</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- Snackbar para mostrar avisos -->
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </div>
</template>

<script>
import vueSignature from '@/components/vueSignature.vue';
import { firmaLinkUrl } from '../config/config';

export default {
  components: { 'v-signature': vueSignature },
  data: () => ({
    firma: '',
    loading: true,
    modificar_signature: true,
    disabledButton: false,
    snackbar: false,
    timeout: 5000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    option: {
      penColor: 'rgb(46, 59, 165)',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    loading_upload: false,
  }),

  created() {
    const postData = {
      accion: 'index',
      key: this.$route.params.key,
    };
    this.$http.post(firmaLinkUrl, postData).then(response => {
      if (response.data === '1') {
        this.loading = false;
      } else if (response.data === '2') {
        window.getApp.$emit('FIRMA_TIMEOUT');
      } else {
        this.$router.push('/login');
      }
    });
  },

  methods: {
    saveFirma() {
      const png = this.$refs.signature.save();
      if (!this.disabledButton) {
        this.disabledButton = true;
        const formData = new FormData();
        formData.append('firma', png);
        formData.append('key', this.$route.params.key);
        formData.append('accion', 'guardar');
        const headers = {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        };
        this.$http
          .post(firmaLinkUrl, formData, { headers: headers })
          .then(response => {
            if (response.status === 200 && response.data === '1') {
              window.getApp.$emit('FIRMA_OK');
            } else {
              this.alerta.texto = this.$t('errors.try_again');
              this.alerta.color = 'error';
              this.alerta.icono = 'mdi-alert-circle';
            }
            this.snackbar = true;
            this.disabledButton = false;
          })
          .catch(err => {
            this.$log.error(err);
          });
      }
    },
    clear() {
      this.modificar_signature = true;
      setTimeout(() => {
        this.$refs.signature.clear();
      }, 500);
    },
  },
};
</script>
<style scoped lang="css">
.instrucciones {
  margin-left: 20px;
}
.firma_nueva {
  border: 2px dashed rgb(221, 221, 221);
  z-index: 99;
  cursor: url(/static/pen.cur), pointer;
  height: 180px;
  background-color: rgb(255, 255, 255);
}
.firma_creada {
  border: 2px dashed rgb(221, 221, 221);
  z-index: 99;
  height: 180px;
  margin-bottom: -5px;
  background-color: rgb(255, 255, 255);
}
.botonera {
  text-align: right;
}
.loading_firma {
  margin-top: 10px;
  text-align: center;
}

.blue {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}
</style>
